<template>
  <b-card>
    <h2 class="c2">
      Tambah data security
    </h2>

    <p class="c3 c4">
      <span class="c2">Akses menu tambah security seperti pada ilustrasi berikut.</span>
    </p>
    <p class="c3 c4">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 305.33px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image26.png')"
          style="width: 601.70px; height: 305.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c3 c4">
      <span class="c2">Untuk menambahkan opsi pengunjung dan kendaraan maka pengguna dapat memilih tombol seperti pada ilustrasi berikut.</span>
    </p>
    <p class="c3 c4">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 305.33px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image12.png')"
          style="width: 601.70px; height: 305.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c3 c4">
      <span class="c2">Maka akan tampil field tambahan seperti berikut.</span>
    </p>
    <p class="c3 c4">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 301.33px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image3.png')"
          style="width: 601.70px; height: 301.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c3 c4">
      <span class="c2">Setelah pengguna mengisi semua field maka dapat memilih tombol simpan untuk menambahkan data security baru seperti berikut. untuk jam keluar dapat di isi ketika pengunjung selesai berkunjung di menu edit data security.</span>
    </p>
    <p class="c0 c4">
      <span class="c2" />
    </p>
    <p class="c3 c4">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 591.00px; height: 288.82px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image16.png')"
          style="width: 1013.62px; height: 334.73px; margin-left: 0.00px; margin-top: -21.30px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0 c4">
      <span class="c2" />
    </p>
    <p class="c3 c4">
      <span class="c2">Setelah sukses maka akan tampil pada daftar data security</span>
    </p>
    <p class="c3 c4">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 194.67px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image22.png')"
          style="width: 601.70px; height: 194.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c3 c4">
      <span class="c2">Jika pengunjung masih belum selesai atau data belum di edit, maka watu selesai akan menunjukkan belum keluar, untuk mengeditnya pengguna dapat memilih menu edit pada kolom aksi sebagai berikut.</span>
    </p>
    <p class="c0 c4">
      <span class="c2" />
    </p>
    <p class="c3 c4">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 198.67px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image21.png')"
          style="width: 601.70px; height: 198.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0 c4">
      <span class="c2" />
    </p>
    <p class="c3 c4">
      <span class="c2">Maka pengguna akan diarahkan menuju halaman edit data security seperti pada ilustrasi berikut.</span>
    </p>
    <p class="c0 c4">
      <span class="c2" />
    </p>
    <p class="c3 c4">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 306.67px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image6.png')"
          style="width: 601.70px; height: 306.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c3 c4">
      <span class="c2">Pada menu edit data security pengguna dapat menambahkan jam keluar pengunjung dan mengubah data yang telah dimasukkan sebelumnya, setelah pengguna selesai melakukan perubahan data maka pengguna dapat memilih tombol simpan untuk menyelesaikan edit data security.</span>
    </p>
    <p class="c0 c4">
      <span class="c2" />
    </p>

  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>
